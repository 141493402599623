.wallet-main{
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    gap: 1em;
}
.wa-head{
    width: 85%;
    text-align: center;
    background-color: var(--txt-dim);
    color: var(--txt-gold);
    font-family: bellfortRough;
    font-size: 1.2em;
    padding: 1em;
    border-radius: .5em;
    font-weight: 700;
}
.wa-cont{
    width: 100%;
    flex: 1;
}
.wa-t{
    background-color: #2b404458;
    display: flex;
    flex-direction: column;
    gap: .5em;
    padding: .5em;
    border-radius: .5em;
}
.wa-t-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wa-t-head span{
    font-family: oswaldRegular;
    font-weight: 600;
    font-size: .9em;
    color: var(--txt-gold);
}
.wa-t-body{
    display: flex;
    flex-direction: column;
    gap: .3em;
}
.wa-entry{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2b40449a;
    padding: .3em;
}
.wa-entry > *{
    width: 100%;
    font-weight: 500;
    color: var(--txt-dim);
    font-size: .8em;
}
.wa-entry > span:nth-child(2), .wa-entry > span:nth-child(3){
    text-align: center;
}
.wa-entry div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .3em;
}
.wa-action-btn{
    width: 60%;
    background-color: var(--txt-dim);
    padding: .3em .5em;
    border-radius: .5em;
    font-family: oswaldRegular;
}
.wa-action-btn:hover{
    box-shadow: 0 0 5px #0e849bfe inset;
}
.auth-main{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auth-container{
    border: 1px solid var(--txt-dim);
    padding: 1em;
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    width: clamp(100px, 100vw, 600px);
}
.auth-logo{
    width: 3.5em;
    height: 3.5em;
}
.auth-header{
    font-size: 2em;
    font-weight: 700;
    text-align: center;
    font-family: bellfortBold;
    color: var(--txt-gold);
}
.auth-form-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5em;
}
.auth-label{
    font-size: .8em;
    font-weight: 600;
    margin: .5em 0;
}
.auth-input{
    width: 100%;
    background-color: var(--primary);
    outline: none;
    border: 1px solid var(--txt-dim);
    padding: .5em;
    font-size: 1em;
    font-family: oswaldRegular;
    border-radius: .5em;
    color: var(--txt-gold);
}
.auth-submit-btn{
    width: 100%;
    font-size: 1.2em;
    font-family: oswaldRegular;
    padding: .5em;
    border-radius: .5em;
    background-color: var(--txt-dim);
    margin: 1em 0;
}

@media screen and (max-width : 480px) {
    .auth-container{
        border: none;
    }
}
.home-main{
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    padding-top: 2em;
}
.users-splash{
    width: 80%;
    border-radius: .5em;
    padding: .5em;
    background-color: var(--txt-dim);
    display: flex;
    align-items: baseline;
    gap: 1em;
}
.users-splash p:nth-child(1){
    font-size: 4em;
    font-family: oswaldRegular;
    color: var(--txt-gold);
    font-weight: 700;
}
.users-splash p:nth-child(2){
    font-size: 20px;
    font-weight: 500;
}
.home-actions{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em;
    gap: 1em;
}
.home-actions > *{
    color: var(--txt-gold);
    border: 1px solid var(--txt-dim);
    padding: .5em;
    border-radius: .5em;
}
.layout-main{
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.header{
    padding: .5em 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #666E9742;
}
.org-id{
    display: flex;
    align-items: center;
    gap: .5em;
    font-size: 20px;
    
}
#logo{
    width: 1.8em;
}
.org-id span{
    font-family: bellfortRough;
}
.org-id span:nth-child(2){
    color: var(--txt-gold);
}


.layout-base{
    display: grid;
    grid-template-columns: 15% 85%;
    height: 100%;
}
.layout-base > *{
    height: 100%;
}

.nav{
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 5px 5px #6b70985a;
}
.nav:hover{
    box-shadow: 0 5px 5px var(--txt-dim);
}
#menu-open-btn{
    position: absolute;
    background-color: var(--primary);
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    transform: translateX(3em);
    z-index: 1001;
    top: 0;
    display: none;
}

.nav .body{
    display: flex;
    flex-direction: column;
    padding: 1.5em 0;
    gap: 1em;
}
.nav .nav-btn{
    display: flex;
    padding: .3em;
    justify-content: center;
    align-items: center;
}

.nav .nav-btn span{
    font-family: oswaldRegular;
    color: var(--txt-dim);
    font-size: 18px;
}
.nav .nav-btn.active span{
    color: var(--txt-gold);
}
.nav .end{
    position: absolute;
    bottom: 2em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width : 480px) {
    .layout{
        grid-template-columns: 100%;
        height: 100%;
    }
    .layout-base{
        display: flex;
        flex-direction: column;
    }
    .header{
        padding: .5em;
    }
    .org-id{
        gap: .3em;
        font-size: 20px;
    }
    .nav{
        position: fixed;
        z-index: 1000;
        height: 100%;
        width: 100%;
        left: 0;
        background-color: var(--primary);
        transform: translate(-100vw);
        transition: transform .25s ease-in;
    }
    .nav .body{
        padding: 2em 1em;
        gap: 1em;
        align-items: flex-start;
    }
    .nav .nav-btn{
        flex-direction: row;
        padding: .5em;
        gap: .5em;        
    }
    .nav .end{
        position: absolute;
        bottom: 4em;
        padding: 1em;
        border-radius: .5em;
        background-color: #20273D;
    }
    .nav.active{
        transform: translate(0);
    }
    #menu-open-btn{
        display: block;
    }
    .nav.active #menu-open-btn{
        transform: translateX(0);
        z-index: 1001;
    }
}
.users-main{
    padding: 1em;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
    height: 90vh;
    flex: 1;
    gap: .3em;
}
.users-table{
    width: 100%;
    /* height: calc(100% - 6em); */
    background-color: #2b404458;
    border-radius: .3em;
    /* padding: 0 .5em; */
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.users-table .head{
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    padding: .3em;
    background-color: #2b404415;
    backdrop-filter: blur(10px);
    border-radius: .5em;
    top: 0;
    left: 0;
}
.users-table .head > span{
    text-align: center;
    width: 100%;
    color: var(--txt-gold);
    font-family: oswaldRegular;
    font-size: 18px;
}
.users-body{
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    gap: .3em;
    overflow-y: scroll;
    height: 100%;
}
.user-entry{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1721238d;
    border-radius: .3em;
    height: fit-content;
    padding: .3em;
}
.user-entry > *{
    text-align: center;
    width: 100%;
}
.user-entry > span{
    font-size: 12px;
    color: var(--txt-dim);
}
.user-action-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .3em;
}
.user-action-cont>button{
    width: fit-content;
    background-color: var(--txt-dim);
    border-radius: .5em;
    color: var(--txt-gold);
    padding: .3em 1.2em;
    
}
.user-action-cont>button:hover{
    box-shadow: 0 0 5px #ecba1869;
}

.ov-base{
    position: fixed;
    background-color: #1316206A;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.ov-cont{
    width: clamp(10em,90%,50em);
    /* height: 30vw; */
    padding: 1em;
    border-radius: .5em;
    background-color: #666E9727;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    /* height: 22em; */
}
.ew-head{
    font-family: oswaldRegular;
    /* text-align: center; */
    /* width: 100%; */
    font-size: 15px;
}
.ew-head span{
    color: var(--txt-gold);
    font-family: oswaldRegular;
    font-size: 16px;
    font-weight: 700;
}
.ew-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.ew-inp{
    display: flex;
    flex-direction: column;
    gap: .3em;
}
.ew-inp input, .ew-select{
    width: 50%;
    border-radius: .5em;
    font-family: oswaldRegular;
    background-color: var(--primary);
    outline: none;
    padding: .3em .5em;
    color: var(--txt-gold);
    font-size: 1.1em;
}
.ew-body p{
    font-size: 12px;
    color: var(--txt-dim);
}

.ew-actions{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3em;
}
.ew-req-actions{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1em;
}
.ew-req-actions button, .cancel-ew{
    font-family: oswaldRegular;
    padding: .3em .5em;
    font-size: 1.1em;
    border-radius: .5em;
    /* width: 90%; */
    width: 100%;
}
.ew-req-actions button:hover, .cancel-ew:hover{
    box-shadow: 0 0 10px #131620f2 inset;
}
.ew-req-actions button[disabled=true],.ew-req-actions button:disabled{
    opacity: 0.5;
}
.ew-req-actions button:nth-child(1){
    background-color: #e82020;
}
.ew-req-actions button:nth-child(2){
    background-color: var(--txt-dim);
}
.ew-req-actions button:nth-child(3){
    background-color:  #13750a;
}
.cancel-ew{
    background-color: var(--txt-gold);
}
.rfsh-table{
    position: fixed;
    right: 2em;
    bottom: 2em;
    background-color: var(--txt-gold);
    color: var(--txt-dim);  
    padding: .3em .5em;
    border-radius: .5em;
    box-shadow: 1px 1px .5em #0ae7d5;
    font-family: oswaldRegular;
    font-weight: 700;
}
.rfsh-table:hover{
    box-shadow: 0 0 .5em #0ae7d5 inset;
}

.tr{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: 15em;
    position: relative;
    overflow-y: hidden;
    background-color: #1316206A;
    padding: .3em;
}
.tr-head{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.tr-head span{
    color: var(--txt-gold);
    font-family: oswaldRegular;
    font-size: 1.1em;
    font-weight: 600;
}
.tr-body{
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    max-height: 13em;
    overflow-y: scroll;
    gap: .2em;
}
.tr-entry{
    display: flex;
    justify-content: space-between;
    background-color: #1721238d;
    padding: .2em .5em;
    border-radius: .3em;
}
.tr-entry span{
    color: var(--txt-dim);
    font-size: .8em;
    font-weight: 600;
}
.tr-entry span:nth-child(2){
    text-align: center;
}
.del{
    color: #e82020;
}
@media screen and (max-width: 480px) {
    .ew-inp input{
        width: 100%;
    }
    .ew-req-actions{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        gap: 1em;
    }
    .ew-req-actions button{
        font-family: oswaldRegular;
        padding: .3em .5em;
        font-size: 1.1em;
        border-radius: .5em;
        /* width: 90%; */
        width: 100%;
    }
}